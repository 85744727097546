  <template>
    <main class="home">
   
      <!-- HEADER TOP MENU BAR -->
        <Header></Header>
     
        <div class="LandingPage">
          <div class="container" >
            <section class="left-text-column">
            <div class="container mt-3" v-for="(items, index) in blogDetailData" :key="index">
            <div class="row"> 
              <div class="col-sm-12" >
             <div class="fl w100 blog-banner">
                <img :src="imgPath(items.image[0].file)" :alt="items.imageAltText" v-if="items.ifImage"/>
                <div class=overlay-img></div>
                <h2>{{formatDate(items.date)}}</h2>
                <h1>{{ items.blogTitle }}</h1>
              </div>
              </div>
            </div>
            <div class="row">     
            <div class="col-sm-10" >
                <div class="site-card-title title-text-comp white-text blog-pad">
                    
                    <p class="mt-5" v-html="items.blogDescription"></p>
                </div>
                <div class="fl w100 blog-btn">
                  <button v-if="PreviousData.length!=0" class="fl tbl-btn"><router-link  :to="urlPath(PreviousData[0].slug)" ><span class="fl btn-txt">Previous post</span></router-link></button>
                  <button  v-if="NextData.length!=0" class="fr tbl-btn"><router-link  :to="urlPath(NextData[0].slug)"><span class="fl btn-txt">Next post</span></router-link></button>
                </div>  
            </div>
            </div>
          </div> 
            </section> 
         </div>
        </div>
          
      <!-- footer section -->
            <section class="footer">
              <footer>
                <cookie-law theme="dark-lime"></cookie-law>
              </footer>
              <Footer/>
            </section>
    </main>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sections from "../components/Section";
const _ = require('lodash');
import { mapGetters } from "vuex";
import moment from 'moment'

export default {
  name: "Blog",
  components: {
    Sections,
    Header,
    Footer,
  },
  watch: {
  '$store.state.language': function() {
    console.log('store!!',this.$store.state.language)
  }
},
computed: {
    ...mapGetters(["language", "content"]),
    blogDetailData() {
     var locale='en-US'
        var route=this.$route.path.split('/');
        var routeSlug=this.$route.params.slug;
      // console.log('LLLanngg',this.language.name)
//        if(this.language.name=='english'){
// locale='en-US'
//        }else{
// locale='wales-england'
//        }
      // console.log('content blog!!!', this.content)
        var newArr=[]; 
        if (this.content && this.content.blog) {
            newArr= this.content.blog.filter((el)=> {
              
              if(el.slug!='' && el._fl_meta_.status=='publish'){
                  return el.slug == this.$route.params.slug;
              }
              // else{
              //     return newArr.push('No such entry exist')
              // }
                
            });
            console.log('new arr',newArr)
            return newArr;
        } else return {};
    },
     PreviousData(){
       var filterWithPublish=[];
       var newArr=[]; 
       var prevrecord=[];
        if (this.content && this.content.blog) {
         filterWithPublish= this.content.blog.filter((el)=> {
                  return el._fl_meta_.status=='publish';
            });
          newArr= _.orderBy(filterWithPublish, 'date', 'asc');
          var currentrecordorderid=this.blogDetailData[0].orderNumber;
          var prevorderno=currentrecordorderid-1;
         prevrecord= newArr.filter((el)=> {
                  return el.orderNumber === prevorderno;
            });
          return prevrecord;
        } else return {};
    },
     NextData(){
        var filterWithPublish=[];
       var newArr=[];
       var nextrecord=[]; 
        if (this.content && this.content.blog) {
           filterWithPublish= this.content.blog.filter((el)=> {
                  return el._fl_meta_.status=='publish';
            });
          newArr= _.orderBy(filterWithPublish, 'date', 'asc');
           var currentrecordorderid=this.blogDetailData[0].orderNumber;
          var nextorderno=currentrecordorderid+1;
          nextrecord= newArr.filter((el)=> {
                  return el.orderNumber === nextorderno;
            });
            
          return nextrecord;
        } else return {};
    },
    
    // currentLanguage() {
    //   if (this.language) {
    //     return this.language;
    //   } else return "english";
    // },
  },

 async  mounted() {
    this.scrollToTop()
    var route= this.$route.params.slug
      this.$gtag.event('News', {
            'event_category': 'Article',
            'event_label': 'Page',
            'value': route?route:'SomeNews Article'
      })
      await this.$gtag.query('event', 'screen_view', {
        app_name: 'MyApp',
        screen_name: 'Blog page',
      })
  },


  methods: {
    urlPath(url) {
         return  url;
    },
     imgPath(imageName) {
         return 'https://firebasestorage.googleapis.com/v0/b/newgalecms.appspot.com/o/flamelink%2Fmedia%2F' + imageName+'?alt=media';
    },
    formatDate(value){
      return moment(String(value)).format('DD MM YYYY')
    },

    scrollToTop() {
        window.scrollTo({top: 0,left: 0, behavior: 'smooth'});
 
      }
  },
};
</script>
